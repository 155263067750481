<template>
    <div>
        <b-card>
            <app-view-table :isTitle="false" :loading="loading_list" :filters.sync="filters" :items="items" :fields="fields" :isDropdownMenu="false">
            </app-view-table>  
        </b-card> 
    </div>
</template>
<script>

import axios from "@axios";

export default {
    components: {
    },

    props:["profile"],

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0
            },
            fields: [
                { key: "entry_date", label: this.trans('registraion',5), thStyle: {"min-width": "150px", "max-width": "200px"}, visible: true},
                { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "80px",width: "100%" }, visible: true},
                { key: "distance", label: this.trans('planning-distance',209), thStyle: {"min-width": "100px", "max-width": "200px"}, visible: true},
            ],
            items:[],
        };
    },

    methods: {
        
        getList: function() {
           /*this.loading_list = true;
            axios
                .post("planning/shifts/getRegistrationList", {
                    id_shift_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                   .then((res) => {
                    this.cProfile.registration = res.data;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });*/
        },

        onCreateThis() { 

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            /*this.$watch('profile.registration.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });*/
        },

    },

    

 
};
</script>