<template>
    <div v-if="profile !== null" class="app-navbar-profile-content">
        <b-navbar sticky class="app-navbar-profile">
            <div class="app-navbar-header">
                <b-row>
                  <b-col cols="8">

                  </b-col>
                  <b-col end class="app-navbar-profile-margin-30">
                    <div class="d-flex justify-content-end align-items-center">
                      <div class="font-weight-800 mr-4 mt-1"> {{trans('core-shift-profile', 271)}} </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center">
                    <b-col> 
                        <shifts-profile-status-bar :profile.sync ="profile" />
                    </b-col>        
                </b-row> 
            </div>
        </b-navbar>

        <div>
            <b-row>
              <b-col md="12" lg="8" class="pr-lg-2">
                    <shifts-profile-action-points-component :profile.sync="profile" />
                    <shifts-profile-informations-component class="mb-3" :profile.sync ="profile" />

                    <!-- TABS -->
                    <b-tabs ref="tabs" class="mb-3" justified :nav-class="cOneTab ? 'd-none' : ''" v-if="!isReadOnly">
                        <b-tab :title="trans('registraion',5)" @click="refreshTab(0)">
                            <shifts-profile-registration-component :profile.sync ="profile" />
                        </b-tab>  
                        <b-tab :title="trans('sh-best-match',215)" v-if="[1, 3, 11].includes(profile.shift_data.id_shift_const_status)" @click="refreshTab(1)">
                            <shifts-profile-best-fit-component :profile.sync ="profile" />
                        </b-tab>
                        <b-tab :title="trans('sh-most-experience',215)" v-if="[1, 3, 11].includes(profile.shift_data.id_shift_const_status)" @click="refreshTab(2)">
                            <shifts-profile-best-experience-component :profile.sync ="profile" />
                        </b-tab>   
                    </b-tabs>

                    <!--Collapses -->
                    <app-collapse accordion type="margin" >
                      
                      <b-card no-body class="app-card-profile" >
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('planning-plan-data',173)" :id="'shift-plan'" :isVisible="isReadOnly">
                              <shifts-profile-plan-data-component :profile.sync ="profile" :readOnly="isReadOnly"/>
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile" >
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('sh-activity-log',215)" :id="'shift-history'">
                              <shifts-profile-history-component :profile.sync="profile" />
                          </app-collapse-item>
                      </b-card>
                    </app-collapse>

                </b-col>

                <b-col md="12" lg="4" class="mt-3 mt-lg-0 pl-lg-2">
                     <shifts-profile-flexworker-component v-if="profile.shift_data.id_person_data_main != null && profile.shift_data.id_shift_const_status > 1" class="mb-3" :profile.sync ="profile" />
                     
                     <shifts-profile-additional-functions-component :profile.sync="profile" :readOnly="isReadOnly" class="mb-3"/>                 
                     <shifts-profile-register-hours-component v-if="[6, 9, 10].includes(profile.shift_data.id_shift_const_status)" class="mb-3" :profile.sync ="profile" />   
                </b-col>
            </b-row>    
        </div>

    </div>
</template>

<script>

import axios from "@axios";
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";

//action pointns
import ShiftsProfileActionPointsComponent from './ActionPoints/ShiftsProfileActionPointsComponent.vue';

import ShiftsProfileFlexworkerComponent from './Flexworker/ShiftsProfileFlexworkerComponent.vue';
import ShiftsProfileRegisterHoursComponent from './RegisterHours/ShiftsProfileRegisterHoursComponent.vue';
import ShiftsProfileStatusBar from './StatusBar/ShiftsProfileStatusBar.vue';
import ShiftsProfileInformationsComponent from './Informations/ShiftsProfileInformationsComponent.vue';
import ShiftsProfileAdditionalFunctionsComponent from './AdditionalFunctions/ShiftsProfileAdditionalFunctionsComponent.vue';

//tabs
import ShiftsProfileRegistrationComponent from './Registration/ShiftsProfileRegistrationComponent.vue';
import ShiftsProfileBestExperienceComponent from './BestExperience/ShiftsProfileBestExperienceComponent.vue';
import ShiftsProfileBestFitComponent from './BestFit/ShiftsProfileBestFitComponent.vue';

//accordions
import ShiftsProfilePlanDataComponent from './PlanData/ShiftsProfilePlanDataComponent.vue';
import ShiftsProfileHistoryComponent from './History/ShiftsProfileHistoryComponent.vue';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    ShiftsProfileStatusBar,

    ShiftsProfileActionPointsComponent,

    ShiftsProfileFlexworkerComponent,
    ShiftsProfileAdditionalFunctionsComponent,
    ShiftsProfileRegisterHoursComponent,
    ShiftsProfileInformationsComponent,

    ShiftsProfileRegistrationComponent,
    ShiftsProfileBestExperienceComponent,
    ShiftsProfileBestFitComponent,

    ShiftsProfilePlanDataComponent,
    ShiftsProfileHistoryComponent
  },

  created() {
    this.getData();
  },

  mounted() {},

  computed : {
      isReadOnly(){
        if([5, 6, 7, 8, 9, 10].includes(this.profile.shift_data.id_shift_const_status)){
            //return true;
            return false;
        }else{
            return false;
        }
      },

    cOneTab: {
      get() {
        if([1, 3, 11].includes(this.profile.shift_data.id_shift_const_status)) { 
          return false;
        } else {
          return true;
        }
      }
    }
      
  },

  watch: {
      "profile.refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.getShiftData();
                }                
            },
        },

  },

  data(){
      return {
      
              id_shift_data_main: this.$route.params.id_shift_data_main,
              profile: null,
              active_tab: 0,
          }

  },

  methods: {

    getData(){
      axios
        .get("planning/shifts/getProfile/" + this.id_shift_data_main)
        .then((res) => {
            this.profile = res.data;
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    getShiftData(){
      
      axios
        .get("planning/shifts/getShiftData/" + this.id_shift_data_main)
        .then((res) => {
            if(res.data != null){
              this.profile.shift_data = res.data;  
            }            
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    refreshTab(e){
      if (this.activeTab !== e) {
        this.activeTab = e;
      
        if(e == 0){//Registration
          this.profile.registration.items.refresh ++;
        }else if(e == 1){//Contracten
          //this.profile.contracts_employment.contracts.items.refresh ++;
          //this.profile.contracts_employment.employment.items.refresh ++;
        }else if(e == 2){
            this.profile.experience.items.refresh ++;
        }

      }

    },

    showLogs(){
        this.$router.push({ name: "planning-shifts-profile-history", params: { id_shift_data_main: this.profile.id } });
    },

    resizeEvent(e){
        
    }

  },
 
};

</script>



