<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('sh-change-services',215)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                
                <app-row-left-label :label="trans('date',182)">
                        <app-date id="vi-date-from" v-model="cArgs.date_from" validatorRules="required|date-check" validatorName="Datum" :validatorCustomMessage="{ 'disable-date-past': trans('sh-date-error-7',221) }" :disabledDatesPass="8"/>
                </app-row-left-label>

                 <app-row-left-label :label="trans('planning-start-time',209)" >
                       <app-time id="vi-time-from" v-model="cArgs.hour_from" validatorRules="required" validatorName="Begin tijd"/>
                </app-row-left-label>

                 <app-row-left-label :label="trans('planning-end-time',209)">
                        <app-time id="vi-time-to" v-model="cArgs.hour_to" validatorRules="required" validatorName="Eind tijd"/>
                </app-row-left-label>

                 <app-row-left-label :label="trans('planning-service-type',209)" >
                     <app-radio-group  v-model="cArgs.id_shift_const_daytime" validatorName="Soort dienst" validatorRules="required" :options="day_time" />
                </app-row-left-label>

                <app-divider-modal class="mt-2 mb-3" :isLine="true" v-if="this.cArgs.id_cao_data_main != null"/>

                <app-row-left-label :label="trans('branch',182)">
                    <app-select v-model="cArgs.location" validatorRules="required" validatorName="Vestiging" type="getUserLocations" :disabled="cArgs.id_assignment_data_main"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('location',3)">
                    <app-select v-model="cArgs.client_location" type="getLocationsOffers" :refreshIndex.sync="refreshClientLocations" :args.sync="cArgs" validatorName="Locatie" validatorRules="required" :disable="!cArgs.location || cArgs.id_assignment_data_main"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('departments',3)">
                    <app-select class="w-100" v-model="cArgs.department" type="getDepartments" :refreshIndex.sync="refreshDepartments" :args.sync="cArgs" validatorName="Afdeling" validatorRules="required" :disable="!cArgs.client_location || cArgs.id_assignment_data_main"/>
                </app-row-left-label>

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import ShiftsTypeClass from "@app/Planning/Shifts/Profile/PlanData/ShiftsTypeClass.js";
import { datecheck } from "@validations";
import moment from "moment";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
    },

    watch: {

        "cArgs.hour_from": {
            handler: function() {
                this.cArgs.id_shift_const_daytime = this.shift_type_class.calculate(this.cArgs.hour_from, this.cArgs.hour_to)
            }
        },

        "cArgs.hour_to": {
            handler: function() {
               this.cArgs.id_shift_const_daytime = this.shift_type_class.calculate(this.cArgs.hour_from, this.cArgs.hour_to)
            }
        },

        "cArgs.location": {
            handler: function(val) {
              if (this.cArgs.location !== null && !this.loading) {
                    
                    this.cArgs.id_office_data_location = this.cArgs.location.value;
                   
                    if (this.cArgs.client_location  != null){
                        this.cArgs.client_location.name = "";
                        this.cArgs.client_location.value = null;
                        this.cArgs.client_location = null;
                    }
                    
                    if (this.cArgs.department  != null){
                        this.cArgs.department.name = "";
                        this.cArgs.department.value = null;
                        this.cArgs.department.id_cao_data_main = null;
                        this.cArgs.department.cao_source = null;
                        this.cArgs.department.zzp_preferences = null;
                        this.cArgs.department = null;
                        
                    }

                    this.refreshClientLocations++;
                }
            }
        },

        "cArgs.client_location": {
            handler: function(val) {
               
                if (this.cArgs.client_location !== null) {
                    this.cArgs.id_location_data_main = this.cArgs.client_location.value;

                    if (this.cArgs.department  != null){
                        this.cArgs.department.name = "";
                        this.cArgs.department.value = null;
                        this.cArgs.department = null;
                    }

                    this.refreshDepartments++;
                }
            },   
            deep: true,     
        },
    },

    data() {
        return {
            loading: false,
            shift_type_class :new ShiftsTypeClass(),
            day_time: [
                { value: 1, text: this.trans('day-part-morning',182) },
                { value: 2, text: this.trans('day-part-afternoon',182) },
                { value: 3, text: this.trans('day-part-day',182) },
                { value: 4, text: this.trans('day-part-evening',182) },
                { value: 5, text: this.trans('day-part-night',182) },
                { value: 6, text: this.trans('sleeping-service',182) }
            ],
            refreshClientLocations: 0,
            refreshDepartments: 0,
             
        };
    },

    methods: {
        change() {            
          
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }
                this.loading = true;

                axios
                    .post("planning/offers/changeShifts", {
                        ids_shift_data_main: JSON.stringify(this.args.ids_shift_data_main),

                        date_from: this.cArgs.date_from,
                        hour_from: this.cArgs.hour_from,
                        hour_to: this.cArgs.hour_to,
                        
                        id_office_data_location: this.cArgs.location.value,
                        id_location_data_main: this.cArgs.client_location.value,
                        id_department_data_main: this.cArgs.department.value,
                        id_cao_data_main: this.cArgs.department.id_cao_data_main,
                        type: this.cArgs.type,
                        id_shift_const_daytime: this.cArgs.id_shift_const_daytime
                    })
                    .then((res) => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);
                        this.cReturn.shift_data = res.data;                     
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading = false;
                    });
                })
        },


        closeModal() {
            this.$emit("update:show", false);
        },




    },
};
</script>

<style scoped>

</style>
