<template>
    <div>
        <b-card>
            <app-view-table :isTitle="false" :loading="loading_list" :filters.sync="filters" :items="profile.experience.items" :fields="fields" :isDropdownMenu="false" :topFiltersCount="1">
                <template v-slot:filter-col-1>
                    <b-button-group  size="sm">
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.list_type !== 1, 'app-list-button-selected':  filters.list_type === 1 }" @click="setListType(1)">{{trans('department',182)}}</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.list_type !== 2, 'app-list-button-selected':  filters.list_type === 2 }" @click="setListType(2)">{{trans('location',3)}}</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.list_type !== 3, 'app-list-button-selected':  filters.list_type === 3 }" @click="setListType(3)">{{trans('organization',169)}}</b-btn>
                    </b-button-group> 
                </template>
            </app-view-table>  
        </b-card> 
    </div>
</template>
<script>

import axios from "@axios";

export default {
    components: {
    },

    props:["profile"],

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0,
                list_type: 1,
            },
            fields: [
                { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "80px",width: "100%" }, visible: true},
                { key: "function_name", label: this.trans('function',3), thStyle: {"min-width": "200px", "max-width": "200px"}, visible: true},
                { key: "phase", label: this.trans('agreement',3), thStyle: {"min-width": "150px", "max-width": "150px"}, visible: true},
                { key: "office_name", label: this.trans('branch',182), thStyle: {"min-width": "150px", "max-width": "150px"}, visible: true},
                { key: "shifts", label: this.trans('services',3), thStyle: {"min-width": "130px", "max-width": "200px"}, visible: true},
            ],
            items:[],
        };
    },

    methods: {
        
        getList: function() {
           this.loading_list = true;
            axios
                .post("planning/shifts/getExperienceList", {
                    id_shift_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters)
                })
                   .then((res) => {
                    this.cProfile.experience = res.data;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        setListType(type){
            this.filters.list_type = type;
        },

        onCreateThis() { 

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('profile.experience.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },

    },

    

 
};
</script>