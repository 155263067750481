<template>
    <b-modal ref="modal-add" size="md" dialog-class="app-local-max-width" v-model="computedShow" no-close-on-backdrop :title="trans('sw-offer-to-client',215)">

        <ValidationObserver ref="form" >

            <app-row-left-label :label="trans('sh-client-confirmation',219)">
                    <app-select v-model="cArgs.client_confirmation" :options="client_confirmation_options" reduceValue validatorRules="required" validatorName="Klantbevestiging" :clearable="false" />
            </app-row-left-label>
            
            <app-row-left-label :label="trans('send-offer-email',182)">
                    <app-select v-model="cArgs.send_notification" :options="send_notification_options" reduceValue validatorRules="required" validatorName="Aanbiedingsmail versturen" :clearable="false" :disable="args.registration_tool == '1'"/>
            </app-row-left-label>

            <b-alert variant="warning" show class="mb-0 mt-2" v-if="args.registration_tool == 1">
                <div class="text-md-start">{{trans('offering-through-customer-planning-system',180)}}</div> 
            </b-alert> 

        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary"  @click="add" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>

    </b-modal>
</template>
<script>
import axios from "@axios";
import {  ValidationObserver } from "vee-validate";

export default {

    components:{
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    created(){
        
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        }

    },

    data(){
        return{
            loading: false,
            client_confirmation: null,
            send_notification: null,
            client_confirmation_options: [ { value: 1, name: this.trans('yes-option',3) }, { value: 0, name: this.trans('no-option',3) }],
            send_notification_options: [ { value: 0, name: this.trans('directly',3) }, { value: 1, name: this.trans('put-in-queue',180) }, {value: 2, name: this.trans('no-option',3)}],
        }
    },

    methods: {

        add(){
            this.$refs.form.validate().then((result) => {

                if (!result) {
                    return false;
                }
               else{

                   this.loading = true;

                   axios
                        .post("planning/shifts/person/suggestToClient", {
                            id_shift_ref_applicants: this.cArgs.id_shift_ref_applicants,
                            id_shift_data_main: this.cArgs.id_shift_data_main,
                            registration_tool: this.cArgs.registration_tool,
                            client_confirmation: this.cArgs.client_confirmation,
                            send_notification: this.cArgs.send_notification,
                            filters: this.args.filters,
                            return_type: this.args.return_type,
                        })
                        .then((res) => {
                            this.cReturn= res.data;
                            this.$emit('update:result', 1);
                        })
                        .catch((error) => {
                            console.error(`error: ${error}`);
                        })
                        .finally(() => {
                            if(this.args.return_type == 'shift_profile'){
                                this.cReturn.history.items.refresh ++;
                            }
                            this.loading = false;
                            this.computedShow = false;
                        })

               }
            });

        },

        getDateAndHours() { 
            let start_hour = parseInt(this.cArgs.shift_data.shift_hour_from.slice(0,2));
            let start_minutes = this.cArgs.shift_data.shift_hour_from.slice(3);
            let end_hour = parseInt(this.cArgs.shift_data.shift_hour_to.slice(0,2));
            let end_minutes = this.cArgs.shift_data.shift_hour_to.slice(3);

            return start_hour + '<sup class="ml-1">' + start_minutes + '</sup> - '+ end_hour + '<sup class="ml-1">' + end_minutes + '</sup> ';
        },

        closeModal() {
            this.$emit("update:show", false);
        },
    }
    
}
</script>
<style>

.app-action-status-gray-text{
    font-size: 12px;
    color: #8898aa;
}

.app-local-max-width{
    max-width: 550px;
}
</style>