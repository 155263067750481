<template>
    <div>
        <b-card no-body class="app-card-profile"> 
            <b-row>
                <b-col start>
                    <div class="app-card-profile-title mb-3">
                        {{trans('planning-functions',173)}}
                    </div>
                </b-col>              
            </b-row>
            <b-row class="app-component-icon-edit">
                <b-dropdown variant="link"  no-caret right v-if="user_role < 3 || profile.can_user_edit">
                    <template #button-content>
                        <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                    </template>
                    <b-dropdown-item @click="addFunction()">{{trans('change',175)}}</b-dropdown-item>
                </b-dropdown>
            </b-row> 

            <b-row > 
                <b-col cols="4">
                    <p class="col-form-label-ellipsis"><b>{{trans('planning-main-function',209)}}</b></p>                                 
                </b-col>

                <b-col cols="8">
                    <p class="col-form-value-ellipsis"><b>{{ profile.additional_functions.items.function_name ? profile.additional_functions.items.function_name : '-'}}</b></p>
                </b-col>
            </b-row>

            <b-row v-for="item in profile.additional_functions.items.list" :key="item.name"> 
                <b-col cols="4">
                    <p class="col-form-label-ellipsis">{{trans('deviant-funtions',182)}}</p>                                  
                </b-col>

                <b-col cols="8">
                    <p class="col-form-value-ellipsis">{{ item.name}}</p>
                </b-col>
            </b-row>

        </b-card>
        <shifts-profile-additional-functions-add v-if="mAdditionalAdd.show" :show.sync="mAdditionalAdd.show" :args.sync="mAdditionalAdd.args" :result.sync="mAdditionalAdd.result" :return.sync="profile" />
    </div>
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import ShiftsProfileAdditionalFunctionsAdd from './ShiftsProfileAdditionalFunctionsAdd.vue';

export default {

    props:["profile", "readOnly"],

    components: {
        ShiftsProfileAdditionalFunctionsAdd
    },

    computed: {
        cProfile: {
            get() {return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    mounted() {
        this.onCreateThis();
    },

    data(){
        return{
            loading_list: false,
            refresh:0,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {},
                page: 1,
                per_page: 10,
                changed: 0
            },
            fields: [
                { key: "name", label: "Name", thStyle: { width: "100%" }, visible: true, sortable: false },            
            ],
            mAdditionalAdd: { show: false, args: {
                id_shift_data_main: null,
                type: null,
                additional_functions:null,
                ids_function_data_main: null,
                id_cao_data_main:null,
                additional_functions: null,
            }, result: 0, return: null },
            alertClass: new AppAlerts(),
            list:[],
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

     methods:{

         getList: function() {
            this.loading_list = true;
            axios
                .post("planning/shifts/getAdditionalFunctionsList", {
                    id_shift_data_main: this.profile.id,
                    return_type: 'shift_profile',
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.list = JSON.parse(JSON.stringify(res.data.items.list));
                    this.cProfile.additional_functions.items = res.data.items;
                    this.filters.page = res.data.page;
                    this.cProfile.additional_functions.items.list = this.profile.additional_functions.items.list.slice(this.filters.page*10-10, this.filters.page*10);
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        addFunction() {
            this.list = JSON.parse(JSON.stringify(this.profile.additional_functions.items.list));
            this.mAdditionalAdd.args.additional_functions = JSON.parse(JSON.stringify(this.list));
            this.mAdditionalAdd.args.id_shift_data_main = this.cProfile.id;
            this.mAdditionalAdd.args.type = this.cProfile.type;
            this.mAdditionalAdd.args.ids_function_data_main = [this.profile.shift_data.id_function_data_main].concat(this.profile.additional_functions.items.ids_function_data_main);
            this.mAdditionalAdd.args.id_cao_data_main = this.cProfile.shift_data.id_cao_data_main;
            this.mAdditionalAdd.args.additional_functions_json = this.cProfile.shift_data.additional_functions;
            this.mAdditionalAdd.show = true;
        },

        onCreateThis() { 

            this.list = JSON.parse(JSON.stringify(this.profile.additional_functions.items.list));
            if(this.cProfile.additional_functions.items.list != null){
                this.cProfile.additional_functions.items.list = this.profile.additional_functions.items.list.slice(this.filters.page*10-10, this.filters.page*10);
            }

            this.$watch('profile.additional_functions.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('mAdditionalAdd.result',function(newVal, oldVal) {
                if(this.mAdditionalAdd.result == 1){
                    this.list = JSON.parse(JSON.stringify(this.cProfile.additional_functions.items.list));
                    //this.cProfile.additional_functions.items.list = this.profile.additional_functions.items.list.slice(this.filters.page*10-10, this.filters.page*10);
                    this.mAdditionalAdd.result = 0;
                }
            });
        },

     }

};
</script>

<style scoped>
</style>