<template>
    <div>
        <b-card>
            <app-view-table :isTitle="false" :key="refresh" :loading="loading_list" :filters.sync="filters" :items="cProfile.registration.items" :fields="fields" :customColumns="customColumns" >
                <template v-slot:avatar="cell">
                    <app-user-avatar :id="cell.data.item.id_person_data_main" :user="getUser(cell.data.item)" :customClass="cell.data.item.entry_type == 0 ? false : true"/>
                </template>

                <template v-slot:distance="cell">
                    <span v-overflow-tooltip v-if="cell.data.item.distance >= 0">{{cell.data.item.distance ? cell.data.item.distance + ' km' : ''}}</span>
                    <span v-overflow-tooltip v-if="cell.data.item.distance == -1">{{trans('sh-no-flexworker-location',215)}}</span>
                    <span v-overflow-tooltip v-if="cell.data.item.distance == -2">{{trans('sh-no-service-location',215)}}</span>
                    <span v-overflow-tooltip v-if="cell.data.item.distance == -3">{{trans('sh-no-location',215)}}</span>
                </template>

                <template v-slot:menu-items="cell">
                    <b-dropdown-item :to="{ name: 'people-person-profile', params: { id_person_data_main: cell.data.item.id_person_data_main }}">{{trans('to-profile',175)}}</b-dropdown-item>
                    <b-dropdown-item v-if="cell.data.item.id_function_data_main != null && (profile.shift_data.id_shift_const_status == 3 || profile.shift_data.id_shift_const_status == 11) && [1].includes(cell.data.item.shift_ref_applicant_status_id) && (user_role < 3 || profile.can_user_edit)" @click="suggestClient(cell.data.item)">{{trans('sh-offer-to-client',220)}}</b-dropdown-item>
                    <b-dropdown-item v-if="[3].includes(cell.data.item.id_shift_const_status) && [1].includes(cell.data.item.shift_ref_applicant_status_id) && (user_role < 3 || profile.can_user_edit)" @click="deleteRegistration(cell.data.item)">{{trans('decline',175)}}</b-dropdown-item>                   
                    <b-dropdown-item v-if="cell.data.item.id_function_data_main == null" @click="setFunction(cell.data.item)">{{trans('cp-select-function',192)}}</b-dropdown-item>                   
                </template>

                <template v-slot:function_name="cell">
                    <b-row>
                        <b-col cols="9"><span v-overflow-tooltip>{{cell.data.item.function_name}}</span></b-col>
                        <b-col cols="auto" end>
                            <b-icon v-if="cell.data.item.id_function_data_main == null" variant="danger" end icon="exclamation-circle-fill" data-toggle="tooltip" data-placement="top" :title="trans('pl-function-not-set',215)"></b-icon>
                            <b-icon v-if="cell.data.item.function_source == 1" end icon="info-circle-fill" data-toggle="tooltip" data-placement="top" :title="trans('pl-function-from-frexworker',215)"></b-icon>
                            <b-icon v-if="cell.data.item.function_source == 2" end icon="info-circle-fill" data-toggle="tooltip" data-placement="top" :title="trans('pl-function-from-shift',215)"></b-icon>
                        </b-col>
                    </b-row>
                </template>

            </app-view-table>  
        </b-card> 
        <shifts-profile-registration-suggest-client v-if="mSuggestClient.show" :show.sync="mSuggestClient.show" :args.sync="mSuggestClient.args" :result.sync="mSuggestClient.result" :return.sync="cProfile" />
        <shifts-profile-registration-set-function v-if="mSetFunction.show" :show.sync="mSetFunction.show" :args.sync="mSetFunction.args" :result.sync="mSetFunction.result" :return.sync="cProfile" />
    </div>
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import AppUserAvatar from '@core/components/AppUserAvatar.vue';
import ShiftsProfileRegistrationSuggestClient from './ShiftsProfileRegistrationSuggestClient.vue';
import ShiftsProfileRegistrationSetFunction from './ShiftsProfileRegistrationSetFunction.vue';


export default {
    components: {
        AppUserAvatar,
        ShiftsProfileRegistrationSuggestClient,
        ShiftsProfileRegistrationSetFunction
    },

    props:["profile"],

    mounted() {
      this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) {this.$emit('update:profile', value) }
        }

    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {                        
                    statuses: { type: 'list', changed: 0, values: [], function: "getTranslationsTypes", parameters: null, widthClass:'app-width-min-250'},
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0
            },
            fields: [
                { key: "entry_date", label: this.trans('registraion',5), thStyle: {"min-width": "150px", "max-width": "200px"}, visible: true},
                { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "80px",width: "100%" }, visible: true},
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "180px", "max-width": "300px"}, visible: true},
                { key: "type_name", label: this.trans('agreement',3), thStyle: { "min-width": "130px", "max-width": "200px" }, visible: true},
                { key: "person_office_name", label: this.trans('branch',182), thStyle: {"min-width": "130px", "max-width": "200px"}, visible: true},
                { key: "shift_ref_applicant_status", label: this.trans('status',3), thStyle: { "min-width": "130px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "statuses"},
                { key: "distance", label: this.trans('planning-distance',209), thStyle: {"min-width": "130px", "max-width": "200px"}, visible: true},
            ],
            items: [],
            refresh: 0,
            customColumns: ["distance", "avatar", "function_name"],
            alertClass: new AppAlerts(),

            mSuggestClient: { show: false, args: { 
                shift_data: null,
                id_shift_ref_applicants: null,
                id_shift_data_main: null,
                id_client_data_main: null,
                id_person_data_main: null,
                registration_tool: null,
                send_notification: null,
                client_confirmation: null,
                filters:null,
                return_type: null,
                }, result: 0, return: null },

            mSetFunction: { show: false, args: { 
                                                id_shift_data_main: null,
                                                id_person_data_main: null,
                                                id_shift_ref_applicants: null,
                                                filters: null,
                                                return_type: null
                                               },
                            result: 0,
                            return: null },

            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        };
    },

    methods: {
        getList: function() {
           this.loading_list = true;
           axios
                .post("planning/shifts/getRegistrationList", {
                    id_shift_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                   .then((res) => {
                    this.cProfile.registration = res.data;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        deleteRegistration: function(data) {
            
            var ids = [];
            
            this.alertClass.openAlertConfirmation(this.trans('sh-reject-applicant-confirmation',221, {personNameApplicant : data.person_name})).then(res => {
                if(res == true){
                    this.loading_list = true;
                    
                    ids = [data.id_shift_ref_applicants];

                    axios
                        .post("planning/shifts/person/reject", {
                            filters: JSON.stringify(this.filters),
                            ids_shift_ref_applicants: JSON.stringify(ids),
                            id_shift_data_main: this.profile.id,
                            return_type: 'shift_profile_registration_list'
                        })
                        .then((res) => {
                            this.cProfile.registration = res.data;
                            this.filters.page = res.data.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.cProfile.refresh ++;
                            this.cProfile.history.items.refresh ++;
                            this.loading_list = false;
                        });
                }     
            }); 
        },

        getUser(item){
            if(item.entry_type == 0){
                return {
                    shortcut_name: item.user_shortcut_name,
                    first_name: item.user_name,
                };
            }else if(item.entry_type == 1){
                return {
                    shortcut_name: 'P',
                    first_name: 'Portal HappyNurse',
                };
            }
        },

        suggestClient(item){
            
            this.mSuggestClient.args.id_shift_ref_applicants = item.id_shift_ref_applicants;
            this.mSuggestClient.args.id_shift_data_main = item.id_shift_data_main;
            this.mSuggestClient.args.client_confirmation = item.client_confirmation;
            this.mSuggestClient.args.registration_tool = item.registration_tool;
            this.mSuggestClient.args.send_notification = item.send_notification;
            this.mSuggestClient.args.shift_data = this.profile.shift_data;
            this.mSuggestClient.args.return_type = 'shift_profile';
            this.mSuggestClient.show = true;
        },

        setFunction(item){
            
            this.mSetFunction.args.id_shift_data_main = item.id_shift_data_main;
            this.mSetFunction.args.id_person_data_main = item.id_person_data_main;
            this.mSetFunction.args.id_shift_ref_applicants = item.id_shift_ref_applicants;
            this.mSetFunction.args.filters = this.filters;
            this.mSetFunction.args.return_type = "shift_profile_registration_list";
            this.mSetFunction.show = true;
        },

        onCreateThis() { 

        
            this.$watch('filters.result', function(newVal, oldVal) {
                if(newVal == 1){
                    this.refresh++;
                }
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('profile.registration.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
        },

    },

    

 
};
</script>