<template>
    <div>
            <b-row class="app-component-icon-edit" v-if="!readOnly && correctIds.includes(profile.shift_data.id_shift_const_status)">
                <b-dropdown variant="link"  no-caret right v-if="user_role < 3 || profile.can_user_edit">
                    <template #button-content>
                        <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                    </template>
                    <b-dropdown-item @click="change()" v-if="correctIds.includes(profile.shift_data.id_shift_const_status)">{{trans('change',175)}}</b-dropdown-item>
                </b-dropdown>
            </b-row>

            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('branch',182)}}</p>
                    <p class="col-list-label mb-2">{{trans('department',182)}}</p>
                    <p class="col-list-label">{{trans('date',182)}}</p>
                    <p class="col-list-label">{{trans('planning-start-time',209)}}</p>
                    <p class="col-list-label">{{trans('planning-end-time',209)}}</p>
                    <p class="col-list-label">{{trans('planning-service-type',209)}}</p>
                </b-col>
                <b-col>
                    <p class="col-form-value">{{profile.shift_data.office_location_name ? profile.shift_data.office_location_name : '-'}}</p>
                    <p class="col-form-value mb-2">{{profile.shift_data.department_name ? profile.shift_data.department_name : '-'}}</p>
                    <p class="col-form-value">{{profile.shift_data.shift_date_from ? profile.shift_data.shift_date_from : '-'}}</p>
                    <p class="col-form-value">{{profile.shift_data.shift_hour_from ? profile.shift_data.shift_hour_from : '-'}}</p>
                    <p class="col-form-value">{{profile.shift_data.shift_hour_to ? profile.shift_data.shift_hour_to : '-'}}</p>
                    <p class="col-form-value">{{profile.shift_data.daytime_name ? profile.shift_data.daytime_name : '-'}}</p>
            
                </b-col>
            </b-row>
            
            <shifts-profile-plan-data-change v-if="mShiftDataChange.show" :show.sync="mShiftDataChange.show" :result.sync="mShiftDataChange.result" :args.sync="mShiftDataChange.args" :return.sync="profile"/> 
     </div>
</template>
<script>

import ShiftsProfilePlanDataChange from './ShiftsProfilePlanDataChange.vue';

export default {
    components: {
        ShiftsProfilePlanDataChange
    },

    props:["profile", "readOnly"],

    data(){
        return{
            mShiftDataChange: { show: false, args: {
                ids_shift_data_main:[],
                location:{
                    name: null,
                    value: null
                },
                client_location:{
                    name: null,
                    value: null
                },
                department:{
                    name: null,
                    value: null,
                    id_cao_data_main: null,
                },
                date_from: null,
                hour_from: null,
                hour_to:null,
                type: 'shift-profile',
                id_shift_const_daytime: null,
                id_location_data_main: null,
                id_office_data_location: null,
                id_assignment_data_main: null,
            }, result: 0, return: null },
            correctIds: [1,3,11,4,5],
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods:{       
        change(){
            this.mShiftDataChange.args.ids_shift_data_main[0] = this.profile.id;
            this.mShiftDataChange.args.date_from = this.profile.shift_data.shift_date_from;
            this.mShiftDataChange.args.hour_from = this.profile.shift_data.shift_hour_from;
            this.mShiftDataChange.args.hour_to = this.profile.shift_data.shift_hour_to;
            this.mShiftDataChange.args.id_shift_const_daytime = this.profile.shift_data.id_shift_const_daytime;
            this.mShiftDataChange.args.location = {value: this.profile.shift_data.id_office_data_location, name: this.profile.shift_data.office_location_name, users: this.profile.shift_data.location_users, student_recruitment: this.profile.shift_data.student_recruitment};
            this.mShiftDataChange.args.department = {value: this.profile.shift_data.id_department_data_main, name: this.profile.shift_data.department_name, id_cao_data_main: this.profile.shift_data.id_cao_data_main, additional_functions: this.profile.shift_data.client_additional_functions, id_department_data_main: this.profile.shift_data.id_department_data_main, cao_source: this.profile.shift_data.cao_source, zzp_preferences: this.profile.shift_data.zzp_preferences, additional_functions: this.profile.shift_data.client_additional_functions};
            this.mShiftDataChange.args.client_location = {value: this.profile.shift_data.id_location_data_main, name: this.profile.shift_data.client_location_name, id_client_data_main: this.profile.shift_data.id_client_data_main, import_procedure: this.profile.shift_data.import_procedure, registration_process: this.profile.shift_data.registration_process, registration_tool: this.profile.shift_data.registration_tool, client_confirmation: this.profile.shift_data.client_confirmation, send_notification: this.profile.shift_data.send_notification};
            this.mShiftDataChange.args.id_location_data_main = this.profile.shift_data.id_location_data_main;
            this.mShiftDataChange.args.id_office_data_location = this.profile.shift_data.id_office_data_location;
            this.mShiftDataChange.args.id_assignment_data_main = this.profile.shift_data.id_assignment_data_main;
            this.mShiftDataChange.show = true;
        },
    }
 
};
</script>
