<template>
    <b-modal ref="modal-change" size="md" centered hide-header v-model="computedShow">

        <p class="text-center text-primary">
            <b-icon icon="question-circle"  font-scale="6"></b-icon>
        </p>
        <p class="text-center text-base">{{trans('sh-cancellation-reason',215)}}</p>

        <div slot="modal-footer" class=" app-local-justify" style="justify-content: center !important;">
             <div style="display:flex">
                <b-button variant="primary" size="sm" @click="cancel(13)" :disabled="loading" class="app-button">{{trans('s-withdrawn',176)}} <b-spinner v-if="loadingCancelled" class="app-btn-spinner ml-1" small></b-spinner></b-button>
                <b-button variant="primary" size="sm" @click="cancel(2)" :disabled="loading" class="app-button">{{trans('lost',175)}} <b-spinner v-if="loadingLost" class="app-btn-spinner ml-1" small></b-spinner></b-button>
                <b-button variant="primary" size="sm" @click="cancel(14)" :disabled="loading" class="app-button">{{trans('sh-internal-correction',220)}} <b-spinner v-if="loadingCorrection" class="app-btn-spinner ml-1" small /></b-button>
                <b-button variant="cancel" size="sm" @click="closeModal()" :disabled="loading" class="btn-light">{{trans('cancel',175)}}</b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from "@axios";

export default {

    props: ["show", "args", "result", "return"],

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
    },

    data(){
        return{
            loading: false,
            loadingCancelled: false,
            loadingLost: false,
            loadingCorrection: false,
        }
    },

    methods: {

        cancel(id_shift_const_status){

            this.loading = true;
            if(id_shift_const_status == 13){
                this.loadingCancelled = true;
            }else if(id_shift_const_status == 2){
                this.loadingLost = true;
            }else if(id_shift_const_status == 14){
                this.loadingCorrection = true;
            }
            if (this.args.cancel_type == 1) {
                axios
                    .post("planning/shifts/cancelShift", {
                        filters: JSON.stringify(this.args.filters),
                        id_shift_data_main: this.args.id_shift_data_main,
                        id_shift_const_status: id_shift_const_status,
                        return_type: this.args.return_type,
                    })
                    .then((res) => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);
                        if(this.args.return_type == "offers_list"){
                            this.cReturn = res.data.items;
                        }else if(this.args.return_type == "shift_profile"){
                            this.cReturn = res.data;
                        }
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.cReturn.history.items.refresh ++;
                        this.loadingCancelled = false;
                        this.loadingLost = false;
                        this.loadingCorrection = false;
                        this.loading = false;
                    });


            } else if(this.args.cancel_type == 2) {

                axios
                    .post("planning/offers/cancelShifts", {
                        filters: JSON.stringify(this.args.filters),
                        id_shift_data_main: JSON.stringify(this.args.id_shift_data_main),
                        id_shift_const_status: id_shift_const_status,
                        return_type: this.args.return_type,
                    })
                    .then((res) => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);
                        if(this.args.return_type == "offers_list"){
                            this.cReturn = res.data.items;
                        }
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loadingCancelled = false;
                        this.loadingLost = false;
                        this.loadingCorrection = false;
                        this.loading = false;
                    });
            }
        },

        closeModal() {
            this.computedShow = false;
        },
    }
    
}
</script>
<style scoped>
.app-local-justify{
    justify-content: center !important;
    margin: auto;
}
</style>