<template>
    <div>
        <b-row class="align-items-center mb-3">
            <b-col cols="8">
                 <b-row class="align-items-center ml-1 mr-1">
                    <b-col>
                        <div style="font-size: 24px"><span class="app-local-location" v-on:click="openCard(1)">{{profile.shift_data.location_name}}</span>   -  <span class="app-local-location" v-on:click="openCard(2)">{{ profile.shift_data.department_name}}</span></div>
                        <div style="font-size: 16px">{{profile.shift_data.client_name}}</div>                        
                    </b-col>

                    <b-col cols="auto" end>
                        <div style="font-size: 14px;  font-weight: 600; text-align:right">
                            <span v-bind:class="{ dotActive: profile.shift_data.deleted == 0, dotInactive: profile.shift_data.deleted == 1 }"> </span> {{this.profile.shift_data.shift_status_name}} <span v-if="profile.shift_data.is_public == 0"> - {{trans('sh-not-public',215)}} ({{ cRemainingTime }})</span><br>
                            <span class="font-weight-300">{{profile.shift_data.location_street}} {{profile.shift_data.location_house_number}}, {{profile.shift_data.location_city}}</span>  
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="4">
                <b-row class="align-items-center ml-1 mr-1">
                    <b-col>
                        <span> {{ profile.shift_data.shift_date_from }} </span><br>
                        <span class="font-weight-300"> {{ getDayNameAndWeekIndex() }}</span>
                    </b-col>
                    <b-col cols="auto" end>
                        <div class="app-local-time" v-html="getHours()"></div>
                        <div class="font-weight-300 text-right">{{ profile.shift_data.daytime_name }}</div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        
    </div>
</template>
<script>

import moment from "moment";
export default {
    
    props:["profile"],

    mounted(){
        var event = moment(this.profile.shift_data.public_date);
        var diffTime = event.diff(this.currect_time);
        this.duration = moment.duration(diffTime, 'milliseconds', true);
        
        if(this.profile.shift_data.is_public == 0){
            this.interval = setInterval(() => {
                                                this.duration = moment.duration(this.duration - 1000, 'milliseconds');
                                            }, 1000);
        }
        
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        }, 

        cRemainingTime: function(){
            
            if(this.duration != null){

                if(this.duration._milliseconds > 0) {

                    if(this.duration.days() > 0){
                        return ((this.duration.days() * 24) +  this.duration.hours()).toString().padStart(2, '0') + ":" + this.duration.minutes().toString().padStart(2, '0') + ":" + this.duration.seconds().toString().padStart(2, '0');
                    }else if(this.duration.hours() > 0){
                        return this.duration.hours().toString().padStart(2, '0') + ":" + this.duration.minutes().toString().padStart(2, '0') + ":" + this.duration.seconds().toString().padStart(2, '0');
                    }else if(this.duration.minutes() > 0 || this.duration.seconds() > 0){
                        return this.duration.minutes().toString().padStart(2, '0') + ":" + this.duration.seconds().toString().padStart(2, '0');
                    }else{
                        return '00:00';
                    }

                }else{
                    clearInterval(this.interval);
                    this.setPublic();
                }
                   
            }
            return null;
        }

    },

    data(){
        return{
            currect_time: moment(),
            duration: null,
            interval: null
        }
    },

    methods: {
        getHours() { 
            
            var date_from = new Date(this.profile.shift_data.db_shift_date_from);
            var date_to = new Date(this.profile.shift_data.db_shift_date_to);

            return date_from.getHours() + '<sup class="ml-1">' + date_from.getMinutes().toString().padStart(2, '0') + '</sup> - ' +
                   date_to.getHours() + '<sup class="ml-1">' + date_to.getMinutes().toString().padStart(2, '0') + '</sup> ';
        },

        getDayNameAndWeekIndex() {
            moment.locale('nl') 
            return moment(this.profile.shift_data.db_shift_date_from).format('dddd') + ", wk " + moment(this.profile.shift_data.db_shift_date_from).format('ww');
        },

        setPublic(){
            this.cProfile.shift_data.is_public = 1;
        },

        openCard(type){
            if(type == 1){
                this.$router.push({ name: "clients-profile-location", params: { id_location_data_main: this.profile.shift_data.id_location_data_main}  });
            }else if(type == 2){
                this.$router.push({ name: "clients-profile-department", params: { id_department_data_main: this.profile.shift_data.id_department_data_main}  });
            }
        }
    }

};
</script>

<style scoped>
.app-local-time {
    font-size: 20px;  
    font-weight: 600; 
    text-align:right
}

.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #20dc37;
  border-radius: 50%;
  display: inline-block;
}
.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.app-local-location{
    cursor: pointer;
}
.app-local-location:hover{
    text-decoration: underline;
}
</style>
