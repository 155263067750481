<template>
    <div>
        <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('planning-change-functions',173)">
            <ValidationObserver ref="form" >
                <app-row-left-label>
                    <app-multi-select v-model="cArgs.ids_function_data_main" type="getDepartmentsFunctions" :addButtonLabel="trans('planning-add-function',209)" :args.sync="function_args" :isAllOption="false" :label="trans('function',3)" :additionalLabel="trans('deviant-funtions',182)" validatorRules="required" validatorName="Functie" :validatorCustomMessage="{'required': trans('select-at-least-one-function',180)}" :key="refresh"/>
                </app-row-left-label>

            </ValidationObserver>
            <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary"  @click="add" :loading="loading_data">
                {{trans('add',175)}}
            </app-button>
        </template>
        </b-modal>
    </div> 
</template>

<script>
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {


    components: {
        ValidationObserver
    },
    props: ["show", "result", "args", "return"],

    created(){
        if(this.cArgs.ids_function_data_main.length > 0){
            this.id = this.cArgs.ids_function_data_main[0];
        }
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        }

    },

    watch: {
        filters: {
            handler: function(val) {
                if (this.filters.sort_by != "") {
                    this.loading = true;
                    this.getList();
                }
            },
            deep: true,
        },

        filters: {
            handler: function(val) {
                this.getList();
            },
            deep: true,
        },

        "cArgs.ids_function_data_main": {
            handler: function(newVal, oldVal) {
                    if(this.id != this.cArgs.ids_function_data_main[0]){
                        this.addAdditionals();
                        this.id = this.cArgs.ids_function_data_main[0];
                    }
            },
            deep: true,
        },

    },

    data() {
        return {
            id_status_const_type:0,
            filters:{
                search:"",
                sort_by: "fct.name",
                sort_desc: false,
                args:[],
            },
            loading: false,
            loading_data: false,
            items:[],
            ids_function_data_main: [],
            function_args: {id_cao_data_main: this.args.id_cao_data_main},
            id: -1,
            functions_additional: [],
            refresh: 0,
        };
    },


    methods: {

        add() {

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{

                this.loading_data = true;
                axios
                    .post("planning/shifts/changeAdditionalFunctions", {
                        id_shift_data_main: this.cArgs.id_shift_data_main,
                        ids_function_data_main: this.cArgs.ids_function_data_main,
                        return_type: 'shift_profile'
                    })
                    .then((res) => {
                        this.cReturn.additional_functions = res.data;
                        this.$emit('update:result', 1);
                        this.cReturn.history.items.refresh ++;
                        this.cReturn.shift_data.id_function_data_main = res.data.items.id_function_data_main;
                        this.cReturn.shift_data.function_name = res.data.items.function_name;
                    })
                    .catch((error) => {
                        console.error(`error ${error}`);
                    })
                    .finally(() => {
                        this.computedShow = false;
                        this.loading_data = false;
                    });

                }});
        },

        closeModal() {
            this.$emit("update:show", false);
        },

        addAdditionals(){
            if(this.cArgs.additional_functions_json != null){
                var additional_functions_array = JSON.parse(this.cArgs.additional_functions_json)
                this.functions_additional = additional_functions_array;

                var functions = [];
                
                if(this.cArgs.ids_function_data_main[0] != null){
                    this.cArgs.ids_function_data_main = [this.cArgs.ids_function_data_main[0]]
                }
                if(this.functions_additional == null){
                    this.functions_additional = [];
                    return
                }

                for(var i=0; i<this.functions_additional.length; i++){
                    if(this.cArgs.ids_function_data_main.includes(this.functions_additional[i].id_function_data_main)){
                        functions = this.cArgs.ids_function_data_main.concat(this.functions_additional[i].ids_function_data_additional);
                        this.cArgs.ids_function_data_main = functions;
                        functions = [];
                        break;
                    }else if(this.cArgs.ids_function_data_main[0] != null){
                        this.cArgs.ids_function_data_main = [this.cArgs.ids_function_data_main[0]]
                    }
                }
                this.refresh++;
            }
        }

    },
};
</script>

<style scoped>
.app-local-card-size{
    min-height:300px !important; 
    max-height:600px !important
}
.app-local-dropdown{
    width:65px
}
.app-local-icon{
    width:40px !important;
}
.app-local-list-group-custom-style{
    max-height:300px !important;
    overflow-y: auto;
}
.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}
.input-group-text {
    border: none;
}
.ps{
    height: 350px;
}
.card-body {
    padding-top: 0.5rem;
}
</style>
