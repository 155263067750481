<template>
    <div v-if="showActionPoints">
        
        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 1"><!-- OPEN -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>  
                            <span class="text-small">{{trans('cp-select-flex-worker',198)}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="publishShift()" :loading="publish_loading" :disabled="loading || cancel_loading" v-if="profile.shift_data.is_public == 0 && (userRole < 3 || profile.can_user_edit)">{{trans('cp-publish-now',189)}}</app-button>
                        <app-button type="primary" @click="addPerson()" :loading="loading" :disabled="publish_loading || cancel_loading" v-if="(userRole < 3 || profile.can_user_edit)"> {{trans('cp-register-flexworker',189)}} </app-button>
                        
                        <b-dropdown no-caret toggle-class="app-button-multi btn-sm" variant="link" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading" v-if="(userRole < 3 || profile.can_user_edit)">
                            <template #button-content>
                                {{trans('cancel-service',175)}}                       
                            </template>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                        
                        </b-dropdown>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 3"><!-- Aangemeld -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>
                            <span class="text-small">{{trans('cp-register-or-offer-flexworker-to-customer',198)}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="publishShift()" :loading="publish_loading" :disabled="loading || cancel_loading" v-if="profile.shift_data.is_public == 0 && (userRole < 3 || profile.can_user_edit)">{{trans('cp-publish-now',189)}}</app-button>
                        <app-button type="primary" @click="addPerson()" :loading="loading" :disabled="publish_loading || cancel_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('cp-register-flexworker',189)}} </app-button>
                        
                        <b-dropdown no-caret toggle-class="app-button-multi btn-sm" variant="link" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading">
                            <template #button-content>
                                {{trans('cancel-service',175)}}                       
                            </template>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                        
                        </b-dropdown>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 4"><!-- Wacht op klant -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>     
                            <span class="text-small">{{trans('sh-confirm-person-for-service',221, {person_name_applicant : profile.shift_data.person_name_applicant})}}</span>                                 
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="publishShift()" :loading="publish_loading" :disabled="loading || cancel_loading" v-if="profile.shift_data.is_public == 0 && (userRole < 3 || profile.can_user_edit)">{{trans('cp-publish-now',189)}}</app-button>
                        <app-button type="primary" @click="confirmPerson(profile.shift_data.id_shift_ref_applicant, profile.shift_data.id_person_data_applicant, profile.shift_data.person_name_applicant)" :loading="confirm_person_loading" :disabled="confirm_person_loading || cancel_loading || !profile.shift_data.id_function_data_main_applicant" v-if="userRole < 3 || profile.can_user_edit"> {{trans('sh-confirmed-by-client',220)}} </app-button>  
                        <app-button type="primary" @click="rejectPerson(profile.shift_data.id_shift_ref_applicant, profile.shift_data.person_name_applicant, null)" :loading="reject_person_loading" :disabled="reject_person_loading || cancel_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('sh-rejected-by-client',220)}} </app-button>  
                        
                        <b-dropdown no-caret toggle-class="app-button-multi btn-sm" variant="link" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading" v-if="(userRole < 3 || profile.can_user_edit)">
                            <template #button-content>
                                {{trans('cancel-service',175)}}                       
                            </template>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                        
                        </b-dropdown>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <!--&& (profile.shift_data.shift_parameters == null || ((JSON.parse(profile.shift_data.shift_parameters).errors != null && JSON.parse(profile.shift_data.shift_parameters).errors.id == null) || JSON.parse(profile.shift_data.shift_parameters).errors == null) )-->
        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 5  && profile.shift_data.shift_parameters == null"><!-- Bevestigd -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('sh-action-confirmed',215)}}</span>  <br/>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" v-if="differenceDays(profile.shift_data.db_shift_date_from) > -4  && (userRole < 3 || profile.can_user_edit)" @click="rejectPerson(profile.shift_data.id_shift_ref_applicant, profile.shift_data.person_name_applicant, 7)" :disabled="reject_person_loading" :loading="reject_person_loading">{{trans('sh-unsubscribe-flexworker',220)}}</app-button> <!-- jezeli mniej 4 dni do rozpoczecia zmiana status na 7 -->
                        <app-button type="primary" v-if="differenceDays(profile.shift_data.db_shift_date_from) <= -4 && (userRole < 3 || profile.can_user_edit)" @click="rejectPerson(profile.shift_data.id_shift_ref_applicant, profile.shift_data.person_name_applicant, 11)" :disabled="reject_person_loading" :loading="reject_person_loading">{{trans('sh-replacement',220)}}</app-button> <!-- jezeli jest wiecej niz 4 dni do rozpoczenia to reject fukcja-->
                        <app-button type="primary" v-if="showAfterDate(profile.shift_data.db_shift_date_from) && (userRole < 3 || profile.can_user_edit)" @click="noShow(profile.shift_data.id_shift_ref_applicant)" :disabled="no_show_loading">{{trans('sh-no-show',220)}}</app-button> <!-- wiecej niz data startu shiftu i w stasusie 5 -->
                        <!--<app-button type="primary" v-if="showAfterDate(profile.shift_data.db_shift_date_to) && (userRole < 3 || profile.can_user_edit)" @click="registerHours()">{{trans('sh-register-hours',220)}}</app-button>--><!-- wiecej niz date_to i status 5-->
                        
                        <b-dropdown no-caret toggle-class="app-button-multi btn-sm" variant="link" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading" v-if="(userRole < 3 || profile.can_user_edit)">
                            <template #button-content>
                                {{trans('cancel-service',175)}}                       
                            </template>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                            <!--<b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>-->
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                        
                        </b-dropdown>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <!--<app-button type="primary" v-if="(userRole < 3 || profile.can_user_edit)" @click="createHourDeclaratio()" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading" :loading="hour_declaration_loading">{{trans('sh-create-service-declaration',220)}}</app-button>-->

        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 5 && 
                                    (profile.shift_data.shift_parameters != null && 
                                    (JSON.parse(profile.shift_data.shift_parameters).errors != null && JSON.parse(profile.shift_data.shift_parameters).errors.id != null))">

            <b-alert variant="warning" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('pl-calculation-error',213)}}</span><br/>  
                            <span class="text-small">{{JSON.parse(profile.shift_data.shift_parameters).errors.message}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" v-if="differenceDays(profile.shift_data.db_shift_date_from) > -4  && (userRole < 3 || profile.can_user_edit)" @click="rejectPerson(profile.shift_data.id_shift_ref_applicant, profile.shift_data.person_name_applicant, 7)" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || no_show_loading || send_queue_loading || remove_queue_loading || hour_declaration_loading" :loading="reject_person_loading">{{trans('sh-unsubscribe-flexworker',220)}}</app-button> <!-- jezeli mniej 4 dni do rozpoczecia zmiana status na 7 -->
                        <app-button type="primary" v-if="differenceDays(profile.shift_data.db_shift_date_from) <= -4 && (userRole < 3 || profile.can_user_edit)" @click="rejectPerson(profile.shift_data.id_shift_ref_applicant, profile.shift_data.person_name_applicant, 11)" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || no_show_loading || send_queue_loading || remove_queue_loading || hour_declaration_loading" :loading="reject_person_loading">{{trans('sh-replacement',220)}}</app-button> <!-- jezeli jest wiecej niz 4 dni do rozpoczenia to reject fukcja-->

                        <app-button type="primary" v-if="(userRole < 3 || profile.can_user_edit)" @click="createHourDeclaratio()" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading" :loading="hour_declaration_loading">{{trans('sh-create-service-declaration',220)}}</app-button>
                        
                        <b-dropdown no-caret toggle-class="app-button-multi btn-sm" variant="link" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading || hour_declaration_loading" v-if="(userRole < 3 || profile.can_user_edit)">
                            <template #button-content>
                                {{trans('cancel-service',175)}}                       
                            </template>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                           <!-- <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>-->
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                        
                        </b-dropdown>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 6 && profile.shift_data.id_hour_const_status == 1"><!-- Uren registeren -->
             <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('sh-action-register-hours',220)}}</span>  <br/>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="confirmHours()" :loading="loading" v-if="userRole < 3 || profile.can_user_edit">{{trans('sh-confirm-hours',220)}}</app-button>   

                        <b-dropdown no-caret toggle-class="app-button-multi btn-sm" variant="link" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading" v-if="(userRole < 3 || profile.can_user_edit)">
                            <template #button-content>
                                {{trans('cancel-service',175)}}                       
                            </template>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                        
                        </b-dropdown>
                    </div>
                </div>
             </b-alert>
        </b-card>

        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 6 && profile.shift_data.id_hour_const_status == 2"><!-- Uren registeren -->
             <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('sh-confirm-declaration-services-by-client',215)}}</span>  <br/>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="confirmHoursByClient()" :loading="loading" v-if="userRole < 3 || profile.can_user_edit">Bevestigd door klant</app-button>   
                        <app-button type="secondary-grey" @click="rejectHourByClient()" :disabled="loading" v-if="userRole < 3 || profile.can_user_edit">Afwijzen door klant</app-button>
                    </div>
                </div>
             </b-alert>
        </b-card>

        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 6 && profile.shift_data.id_hour_const_status == 4"><!-- Uren registeren -->
             <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">        
                            <span class="align-middle font-weight-600 text-base">Klant afwijzing de declaratie.</span>  <br/>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="confirmHours()" :loading="loading" v-if="userRole < 3 || profile.can_user_edit">{{trans('sh-confirm-hours',220)}}</app-button>   
                    </div>
                </div>
             </b-alert>
        </b-card>

        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 9"><!-- Klant beoordeling -->
             <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('sh-promotion-customer-review',215)}}</span>  <br/>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="addReview()" :loading="loading" v-if="userRole < 3 || profile.can_user_edit">{{trans('sh-leave-review',220)}}</app-button>   

                        <b-dropdown no-caret toggle-class="app-button-multi btn-sm" variant="link" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading" v-if="(userRole < 3 || profile.can_user_edit)">
                            <template #button-content>
                                {{trans('cancel-service',175)}}                       
                            </template>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                        
                        </b-dropdown>
                    </div>
                </div>
             </b-alert>
        </b-card>

        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 11"><!-- Vervanging -->
             <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('sh-action-replacement-flexworker',215)}}</span>  <br/>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                       <app-button type="primary" @click="addPerson()" :loading="loading" :disabled="publish_loading || cancel_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('cp-register-flexworker',189)}} </app-button>

                       <b-dropdown no-caret toggle-class="app-button-multi btn-sm" variant="link" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading" v-if="(userRole < 3 || profile.can_user_edit)">
                            <template #button-content>
                                {{trans('cancel-service',175)}}                       
                            </template>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                        
                        </b-dropdown>
                    </div>
                </div>
             </b-alert>
        </b-card>

        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 12"><!-- Queue -->
             <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('sh-action-send-or-remove-service-from-queue',215)}}</span>                                 
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                       <app-button type="primary" @click="sendQueue()" :loading="send_queue_loading" :disabled="send_queue_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('sh-send-queue',220)}} </app-button>
                       <app-button type="primary" class="app-button-multi-local" @click="removeFromQueue()" :loading="remove_queue_loading" :disabled="remove_queue_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('sh-remove-from-queue',220)}} </app-button>

                       <b-dropdown no-caret toggle-class="app-button-multi btn-sm" variant="link" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading" v-if="(userRole < 3 || profile.can_user_edit)">
                            <template #button-content>
                                {{trans('cancel-service',175)}}                       
                            </template>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                        
                        </b-dropdown>
                    </div>
                </div>
             </b-alert>
        </b-card>

        <b-card class="mb-3" v-if="profile.shift_data.id_shift_const_status == 15"><!-- Wacht op flexwerker -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>     
                            <span class="text-small">{{trans('sh-confirm-person-for-service', 221, {person_name_applicant : profile.shift_data.person_name_applicant})}}</span>                                 
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="confirmPerson(profile.shift_data.id_shift_ref_applicant, profile.shift_data.id_person_data_applicant, profile.shift_data.person_name_applicant)" :loading="confirm_person_loading" :disabled="confirm_person_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('sh-confirmed-by-flexworker',220)}} </app-button>  
                        <app-button type="primary" @click="rejectPerson(profile.shift_data.id_shift_ref_applicant, profile.shift_data.person_name_applicant, null)" :loading="reject_person_loading" :disabled="reject_person_loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('sh-rejected-by-flexworker',220)}} </app-button>  
                        
                        <b-dropdown no-caret toggle-class="app-button-multi btn-sm" variant="link" :disabled="loading || publish_loading || cancel_loading || confirm_person_loading || reject_person_loading || no_show_loading || send_queue_loading || remove_queue_loading" v-if="(userRole < 3 || profile.can_user_edit)">
                            <template #button-content>
                                {{trans('cancel-service',175)}}                       
                            </template>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                            <b-dropdown-item v-if="(userRole < 3 || profile.can_user_edit)" @click="cancelShift(17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>
                        
                        </b-dropdown>
                    </div>
                </div>
            </b-alert>           
        </b-card>        

        <!--<shifts-profile-register-hours-add v-if="mRegisterHoursAdd.show" :show.sync="mRegisterHoursAdd.show" :result.sync="mRegisterHoursAdd.result" :args.sync="mRegisterHoursAdd.args" :return.sync="profile"/> -->
        <hours-profile-confirm v-if="mConfirmHours.show" :show.sync="mConfirmHours.show" :result.sync="mConfirmHours.result" :args.sync="mConfirmHours.args" :return.sync="cProfile"/> 
        <hours-profile-reject-by-client v-if="mRejectByClient.show" :show.sync="mRejectByClient.show" :result.sync="mRejectByClient.result" :args.sync="mRejectByClient.args" :return.sync="cProfile"/> 
        <shifts-profile-cancel-component v-if="mShiftCancel.show" :show.sync="mShiftCancel.show" :result.sync="mShiftCancel.result" :args="mShiftCancel.args" :return.sync="cProfile.shift_data" />
        <shifts-profile-action-points-no-show v-if="mNoShow.show" :show.sync="mNoShow.show" :result.sync="mNoShow.result" :args="mNoShow.args" :return.sync="cProfile.shift_data" />


    </div>
</template>
<script>
import axios from "@axios";
import moment from "moment";
import AppAlerts from '@core/scripts/AppAlerts';
//import ShiftsProfileRegisterHoursAdd from "../RegisterHours/ShiftsProfileRegisterHoursAdd.vue";
import HoursProfileConfirm from "@app/Planning/Hours/Profile/HoursProfileConfirm.vue";
import HoursProfileRejectByClient from "@app/Planning/Hours/Profile/HoursProfileRejectByClient.vue";
import ShiftsProfileCancelComponent from '@app/Planning/Shifts/Profile/ShiftsProfileCancelComponent.vue';
import ShiftsProfileActionPointsNoShow from './ShiftsProfileActionPointsNoShow.vue';

export default {
    components: {        
        //ShiftsProfileRegisterHoursAdd,
        HoursProfileConfirm,
        HoursProfileRejectByClient,
        ShiftsProfileCancelComponent,
        ShiftsProfileActionPointsNoShow
    },

    props:["profile"],

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },
    
    watch: {
        "mShiftCancel.result": {
            handler: function(val) {
                if(this.mShiftCancel.result == 1){
                    this.mShiftCancel.result = 0;
                    this.cProfile.registration.items.refresh ++;
                }               
            },
        },
        deep: true,

        "mNoShow.result": {
            handler: function(val) {
                if(this.mNoShow.result == 1){
                    this.mNoShow.result = 0;
                    this.cProfile.history.items.refresh ++;
                    this.cProfile.registration.items.refresh ++;
                }               
            },
        },
        deep: true,
    },

    computed: {
        showActionPoints () {
            if ((this.profile.shift_data.id_shift_const_status > 0 && this.profile.shift_data.id_shift_const_status < 7) || 
                this.profile.shift_data.id_shift_const_status == 9 ||
                this.profile.shift_data.id_shift_const_status == 11 ||
                this.profile.shift_data.id_shift_const_status == 12 ||
                this.profile.shift_data.id_shift_const_status == 15) {
                return true;
            } else {
                return false;
            }
        },

        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        } 
    
    },

    data() {
        return {
            alert_class : new AppAlerts(),
            loading: false,
            publish_loading: false,
            cancel_loading: false,
            confirm_person_loading: false,
            reject_person_loading: false,
            no_show_loading: false,
            send_queue_loading: false,
            remove_queue_loading: false,
            hour_declaration_loading: false,
            /*mRegisterHoursAdd: { show: false, args: {
                id_shift_data_main: null, 
                id_person_data_main: null,
                date_from: null,
                hour_from: null,
                hour_to: null,
                pause: null,
                }, result: 0, return: null }, */
            mConfirmHours: { 
                show: false, args: {
                                    id_shift_data_main: null,
                                    id_hour_data_main: null,
                                    time_from: null,
                                    time_to: null,
                                    person_description: null,
                                    client_description: null,
                                    hours_json: null,
                                    pause: null,
                                    return_type: null
                                }, 
                result: 0, return: null },
            mShiftCancel: { show: false, args: {
                ids_shift_data_main: null,  
                cancel_type:null,           
                filters: null,
                return_type: null,
            }, result: 0, return: null },
            mNoShow: { show: false, args: {
                id_shift_data_main: null,  
                id_shift_ref_applicants:null,    
                description:null,
                responsibility:null,
            }, result: 0, return: null },
            mRejectByClient:{
                show: false, args: {
                                    id_shift_data_main: null,
                                    id_hour_data_main: null,
                                    return_type: null
                                }, 
                result: 0, return: null
            }
        };
    },

    methods: {

        addPerson(){
            let ids_shift_data_main = [this.profile.id];
                
            this.$router.push({ name: "planning-shifts-add-person", params: { ids_shift_data_main: ids_shift_data_main,
                                                                              start_date: this.profile.shift_data.shift_date_from,
                                                                              returnType: "shift_card",
                                                                              returnIdType: this.profile.id }});
        },

        cancelShift(id_shift_const_status){

             this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => { 

                if(res == true){

                    this.cancel_loading = true;

                    let post_data = {
                        ids_shift_data_main: JSON.stringify([this.profile.id]),
                        id_shift_const_status: id_shift_const_status,
                        return_type: "shift_profile"
                    };

                    axios
                        .post("planning/shifts/cancel", post_data)
                        .then((res) => {                 
                            this.cProfile.shift_data = res.data;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.cProfile.history.items.refresh ++;
                            this.cancel_loading = false;
                        });
                }

             });
            
        },

        publishShift(){
            this.alert_class.openAlertConfirmation(this.trans('sh-publish-service-confirmation',221)).then(res => {

                if(res == true){

                    this.publish_loading = true;

                    axios
                        .post('planning/shifts/publish', {
                            id_shift_data_main: this.profile.id
                        })
                        .then((res) => {
                            this.cProfile.shift_data = res.data;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.cProfile.history.items.refresh ++;
                            this.publish_loading = false;
                        })

                }
            });
        },

        registerHours(){
            
            /*this.mRegisterHoursAdd.args.id_shift_data_main = this.profile.id;
            this.mRegisterHoursAdd.args.id_person_data_main = this.profile.shift_data.id_person_data_main;
            this.mRegisterHoursAdd.args.date_from = this.profile.shift_data.shift_date_from;
            this.mRegisterHoursAdd.args.hour_from = this.profile.shift_data.shift_hour_from;
            this.mRegisterHoursAdd.args.hour_to = this.profile.shift_data.shift_hour_to;
            this.mRegisterHoursAdd.args.pause = '15';
            this.mRegisterHoursAdd.show = true;*/
            
        },

        confirmHours(){
            
            this.mConfirmHours.args.id_shift_data_main = this.profile.id;
            this.mConfirmHours.args.id_hour_data_main = this.profile.shift_data.id_hour_data_main;
            this.mConfirmHours.args.time_from = this.profile.shift_data.hours_real_time_from;
            this.mConfirmHours.args.time_to = this.profile.shift_data.hours_real_time_to;
            this.mConfirmHours.args.person_description = this.profile.shift_data.hours_person_description ? this.profile.shift_data.hours_person_description : '';
            this.mConfirmHours.args.client_description = this.profile.shift_data.hours_client_description ? this.profile.shift_data.hours_client_description : '';
            this.mConfirmHours.args.hours_json = JSON.parse(this.profile.shift_data.hours_json);
            this.mConfirmHours.args.pause = this.profile.shift_data.hours_pause_string;
            this.mConfirmHours.args.return_type = "shift_profile";
            this.mConfirmHours.show = true;
        },

        addReview(){
            this.loading = true;

            axios
                .post('planning/shifts/addReview', {
                    id_shift_data_main: this.profile.id
                })
                .then((res) => {
                    this.cProfile.shift_data = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.cProfile.history.items.refresh ++;
                    this.loading = false;
                });
        },

        confirmPerson(idShiftRefApplicant, idPersonDataMain, personNameApplicant){
             this.alert_class.openAlertConfirmation(this.trans('sh-confirm-employee-confirmation',221, {personNameApplicant : personNameApplicant} )).then(res => {

                if(res == true){

                    this.confirm_person_loading = true;

                    axios
                        .post('planning/shifts/person/confirm', {
                            id_shift_data_main: this.profile.id,
                            id_shift_ref_applicants: idShiftRefApplicant,
                            id_person_data_main: idPersonDataMain,
                            registration_tool: this.profile.shift_data.registration_tool,
                            send_notification: this.profile.shift_data.send_notification,
                            return_type: 'shift_profile'
                        })
                        .then((res) => {
                            this.cProfile.shift_data = res.data;
                            this.cProfile.registration.items.refresh ++;
                            this.cProfile.history.items.refresh ++;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.confirm_person_loading = false;
                        });

                }

             });
        },

        rejectPerson(idShiftRefApplicant, personNameApplicant, idShiftConstStatus){

            let alertMessage = "";
            let postData = null;

            if(this.cProfile.shift_data.id_shift_const_status == 5){//bevestigd
                alertMessage = this.trans('confirm-message',180);

                postData = {
                                id_shift_data_main: this.profile.id,
                                ids_shift_ref_applicants: JSON.stringify([idShiftRefApplicant]),
                                return_type: "shift_profile",
                                id_shift_const_status: idShiftConstStatus
                            };
            }else{
                alertMessage = this.trans('sh-reject-applicant-confirmation',221, {personNameApplicant : personNameApplicant});
                postData = {
                                id_shift_data_main: this.profile.id,
                                ids_shift_ref_applicants: JSON.stringify([idShiftRefApplicant]),
                                return_type: "shift_profile"
                            };
            }

            this.alert_class.openAlertConfirmation(alertMessage).then(res => {

                if(res == true){
                    this.reject_person_loading = true;
                    
                    axios
                        .post('planning/shifts/person/reject', postData)
                        .then((res) => {
                            this.cProfile.shift_data = res.data;
                            this.cProfile.registration.items.refresh ++;
                            this.cProfile.history.items.refresh ++;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.reject_person_loading = false;
                        });
                }

            });
        },

        differenceDays(date){
            var today = moment(moment.now());
            var d = today.diff(moment(date, 'YYYY-MM-DD HH:mm'), 'days');
            return d;
        },
       
       showAfterDate(date){

            var today = moment(moment.now());

            if(moment(date, 'YYYY-MM-DD HH:mm') <= today){
                return true;
            }else{
                return false;
            }

       },

       noShow(idShiftRefApplicant){

            this.mNoShow.args.id_shift_data_main = this.profile.id;
            this.mNoShow.args.id_shift_ref_applicants = idShiftRefApplicant;
            this.mNoShow.args.responsibility = null;
            this.mNoShow.args.description = '';
            this.mNoShow.show = true;
       },

       sendQueue(){
            this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {

                if(res == true){
                    this.send_queue_loading = true;
                    
                    axios
                        .post('planning/shifts/queue/send', {
                                ids_shift_data_main: JSON.stringify([this.profile.id]),
                                return_type: "shift_profile"
                            })
                        .then((res) => {
                            this.cProfile.shift_data = res.data;
                            this.cProfile.registration.items.refresh ++;
                            this.cProfile.history.items.refresh ++;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.send_queue_loading = false;
                        });
                }

            });
       },

       removeFromQueue(){

            this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {

                if(res == true){
                    this.remove_queue_loading = true;
                    
                    axios
                        .post('planning/shifts/queue/remove', {
                                ids_shift_data_main: JSON.stringify([this.profile.id]),
                                return_type: "shift_profile"
                            })
                        .then((res) => {
                            this.cProfile.shift_data = res.data;
                            this.cProfile.registration.items.refresh ++;
                            this.cProfile.history.items.refresh ++;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.remove_queue_loading = false;
                        });
                }

            });

       },

       createHourDeclaratio(){

        this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {

            if(res == true){
                    this.hour_declaration_loading = true;
                    
                    axios
                        .post('planning/shifts/createHourDeclaration', {
                                id_shift_data_main: this.profile.id,
                                return_type: "shift_profile"
                            })
                        .then((res) => {
                            this.cProfile.shift_data = res.data;
                            this.cProfile.registration.items.refresh ++;
                            this.cProfile.history.items.refresh ++;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.hour_declaration_loading = false;
                        });
                }


        });

       },

       confirmHoursByClient(){
        this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => { 

            if(res == true){

                this.loading = true;

                axios
                    .post("planning/hours/confirmByClient", {
                        id_shift_data_main: this.profile.id,
                        id_hour_data_main: this.profile.shift_data.id_hour_data_main,
                        return_type: "shift_profile"
                    })
                    .then((res) => {                 
                        this.cProfile.shift_data = res.data;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.cProfile.history.items.refresh ++;
                        this.loading = false;
                    });
            }

            });
       },


       rejectHourByClient(){
            this.mRejectByClient.args.id_shift_data_main = this.profile.id;
            this.mRejectByClient.args.id_hour_data_main = this.profile.shift_data.id_hour_data_main;
            this.mRejectByClient.args.return_type = "shift_profile";
            this.mRejectByClient.show = true;
       }
       

        
    }
 
};
</script>

<style>
.dropdown-item{
    font-weight: 400 !important;
}
</style>
<style scoped>
.app-button-multi-local{
    background-color: #fff !important;
    border-color: #dee2e6 !important;
    color: #174066 !important;
    border-radius: 50rem !important;
    min-height: 34px !important;
}
</style>