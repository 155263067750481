<template>
    <div>
        <b-card no-body class="app-card-profile">
               <b-row class="app-card-header">
                    <b-col >
                        <div class="app-card-profile-title">
                           {{trans('general-data',3)}}
                        </div>
                    </b-col>
                </b-row>
                        
                <b-row class="mt-3">
                    <b-col md="3" xl="3">
                        <b-form-group :label="trans('branch',182)">
                           <span class="app-local-bold-text"> {{ profile.shift_data.office_location_name }}</span>  
                        </b-form-group>
                    </b-col>
                    <b-col md="3" xl="3">
                        <b-form-group :label="trans('cao',182)">
                           <span class="app-local-bold-text" v-if="profile.shift_data.cao_source == 1"  v-b-tooltip.hover.top :title="profile.shift_data.cao_source_name"> {{profile.shift_data.cao_client_short_name}} </span>
                           <span class="app-local-bold-text" v-if="profile.shift_data.cao_source == 2"  v-b-tooltip.hover.top :title="profile.shift_data.cao_source_name"> {{profile.shift_data.cao_location_short_name}} </span>
                           <span class="app-local-bold-text" v-if="profile.shift_data.cao_source == 3"  v-b-tooltip.hover.top :title="profile.shift_data.cao_source_name"> {{profile.shift_data.cao_department_short_name}} </span>
                           
                        </b-form-group>
                    </b-col>
                    <b-col md="3" xl="3">
                        <b-form-group :label="trans('planning-created-by',209)">
                            <span class="app-local-bold-text" v-b-tooltip.hover.top :title="profile.shift_data.create_date"> {{profile.shift_data.create_user_name}} </span>
                        </b-form-group>
                    </b-col>
                    <b-col md="3" xl="3">
                        <b-form-group :label="trans('planning-last-modification',209)">
                            <span class="app-local-bold-text" v-b-tooltip.hover.top :title="profile.shift_data.change_date"> {{profile.shift_data.change_user_name}} </span>
                        </b-form-group>
                    </b-col>
                </b-row>
           
        </b-card>
    </div>
</template>
<script>


export default {

    props:["profile"],

    data(){
        return{
        }
    },

};
</script>

<style scoped>
.app-local-bold-text{
    font-weight:700;
    color:#174066;
    font-size: 14px !important;
}

.app-local-text{
    color:#174066;
    font-size: 12px !important;
}

.tooltip{
    opacity: 1 !important;
}
</style>