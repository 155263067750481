<template>
     <div>
        <app-view-table :isTitle="false" :loading="loading_list" :filters.sync="filters" :items="cProfile.history.items" :fields="fields" :customColumns="customColumns" :isDropdownMenu="false" :loadingInAccordion="true">
            
            <template v-slot:source="cell">
                <settings-users-source-badge-component :type="cell.data.item.source"/>
            </template>

        </app-view-table>
    </div>
</template>
<script>

import axios from "@axios";
import SettingsUsersSourceBadgeComponent from '../../../../Settings/Users/Components/SettingsUsersSourceBadgeComponent.vue';

export default {
    props:["profile"],

    components: {        
        SettingsUsersSourceBadgeComponent
    },

    mounted() {
        this.onCreateThis();
   
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { }
        }
    },

    data() {
        return {
            customColumns:["full_description", "source"],
            loading_list: false,
            filters: {
                page: 1,
                per_page: 10,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0,

                },
                
                filtering: {},
                status: 1,
                changed: 0,
            },
            fields: [
                { key: "entry_date", label: this.trans('entry-date',182), thStyle: {  "min-width": "160px", "max-width": "160px" }, visible: true, filtering: false },
                { key: "user_name", label: this.trans('history-entry-for',182), thStyle: { "min-width": "220px", "max-width": "220px" }, visible: true, filtering: false },
                { key: "source", label: this.trans('sh-source',219), thStyle: { "min-width": "90px", "max-width": "90px" }, visible: true, filtering: false},
                { key: "description", label: this.trans('content',182), thStyle: { "min-width": "300px", "width": "100%" }, tdClass: "table-column-wrap", visible: true, filtering: false},
            ],
            items: [],
        };
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("planning/shifts/history/getList", {
                    id_shift_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.cProfile.history.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });;
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.history.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

        }
    },
 
};
</script>