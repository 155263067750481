<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('cp-select-function',192)">
        <b-overlay :show="loading_functions == true" :opacity="0.0">
            <ValidationObserver ref="form" >
                <b-form @submit.prevent="onSubmit">

                    <app-row-left-label :label="trans('function',3)">
                        <app-radio-group v-model="function_value" :options="functions" :key="refresh" v-if="loading_functions == false" :validatorName="trans('function',3)" validatorRules="required"/>
                    </app-row-left-label>

                </b-form>
            </ValidationObserver>
            <template #overlay>
               <div>
                    <b-spinner  small class="app-spinner-size app-local-spinner"></b-spinner>                         
               </div>
            </template>
        </b-overlay>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="setFunction" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";


export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result","args", "return"],

    mounted(){
        this.getFunctions();
        
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading_functions: false,
            loading: false,
            function_value: null,
            refresh: 0,
            functions: [],
        };
    },

    methods: {

        getFunctions(){
            this.loading_functions= true;
            axios
                .post("planning/shifts/getFunctions", {
                    id_person_data_main : this.cArgs.id_person_data_main,
                    id_shift_data_main : this.cArgs.id_shift_data_main
                })
                .then((res) => {
                    this.functions = [{value: 1, id_function_data_main : res.data[0].id, text: this.trans('person',209) + " - " + res.data[0].name},
                                        {value: 2, id_function_data_main : res.data[1].id, text: this.trans('shift',209) + " - " + res.data[1].name}];
                    this.refresh++;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_functions = false;
                });
        },
        
        setFunction() {

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("planning/shifts/setFunction", {
                            id_function_data_main: this.functions.find(x => x.value == this.function_value).id_function_data_main,
                            id_shift_data_main: this.cArgs.id_shift_data_main,
                            source: this.function_value,
                            id_shift_ref_applicants: this.cArgs.id_shift_ref_applicants,
                            filters: JSON.stringify(this.cArgs.filters),
                            return_type: this.cArgs.return_type
                        })
                        .then((res) => {

                            if(this.cArgs.return_type == 'shift_profile_registration_list'){
                                
                                this.cReturn.registration = res.data;
                                this.cReturn.refresh ++;
                                this.cReturn.history.items.refresh ++;

                            }else if(this.cArgs.return_type == 'registrations_list'){
                                this.cReturn = res.data;
                            }

                            this.$emit('update:result', 1);
                            this.$emit("update:show", false);
      
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
                    }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.app-local-spinner{
    margin-bottom: 6px !important; 
    border-width: 0.14em !important;
    width: 1.2em !important;
    height: 1.2em !important; 
}
</style>
