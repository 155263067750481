<template>
    <div class="d-flex">
        <div class="b-avatar avatar avatar-xs rounded-circle badge-secondary rounded-circle app-local-avatar" v-bind:class="{'app-local-cursor': !show, 'app-local-color': customClass}" tabindex="0" :id="'popover_' + _uid.toString() + id.toString()" @click="openPopover">{{user.shortcut_name}}</div>

        <b-popover v-if="popover_show" :show.sync="popover_show" :target="popover_target" triggers="focus" no-fade placement="top" @hide="popoverHide">

            <b-row class="align-items-center">
                <b-col cols="auto">
                    <div class="mb-0">
                        <p class="mb-0 app-popover-small-text">
                            <span class="font-weight-600"> {{user.first_name}} {{user.inbetween_name}} {{user.last_name}} </span>
                        </p>                        
                    </div>
                </b-col>
            </b-row>

        </b-popover>

    </div>
</template>
<script>
export default {

    components:{

    },

    props:{
        id: {type: Number},
        user: {type: Object},
        show: {type: Boolean, default: true},
        customClass:{type: Boolean, default: false}
    },

    data(){
        return{
            popover_show: false,
            popover_target: null,
            popover_user: null,
        }
    },

    methods: {

        openPopover(){
            if(this.popover_show == false && this.show){
                this.popover_user = true;
                this.popover_target = 'popover_' + this._uid.toString() + this.id.toString();
                this.popover_show = true;
            }
        },

        popoverHide(){
            this.popover_user = null;
            this.popover_target = null;
            this.popover_show = false;
        },

        onClose(){
            this.popover_show = false;
        }
    }
    
}
</script>
<style>
.app-local-avatar {
    font-size: 0.7rem;
}

.app-local-avatar:hover{
    opacity: 0.7;
    cursor: pointer;
}

.app-local-cursor:hover{
    cursor: default !important;
}

.popover{
    opacity: 1 !important;
    
}
.b-popover:not([style*='transform']) { top: 0; } 

.app-local-color{
    background-color: #b6d8f3;
}
</style>