<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('sh-change-services',215)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                
                <app-row-left-label :label="trans('responsibility',182)">
                    <app-select v-model="cArgs.responsibility" :options="responsibility_options" validatorRules="required" :validatorName="trans('responsibility',182)" />
                </app-row-left-label>

                <app-row-left-label :label="trans('td-description',205)">
                    <app-memo id="vi-description" rows="5" class="mt-2" v-model="cArgs.description" :lettersCounter="true"/>
                </app-row-left-label>

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import ShiftsTypeClass from "@app/Planning/Shifts/Profile/PlanData/ShiftsTypeClass.js";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
    },

    data() {
        return {
            loading: false, 
            responsibility_options: [
                { value: 0, name: this.trans('staff_member',182)},
                { value: 1, name: this.trans('branch',182) },
                { value: 2, name: this.trans('core-client',3) }
            ],
        };
    },

    methods: {
        change() {            
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }else{
                    this.loading = true;     
                    axios
                        .post('planning/shifts/person/noShow', {
                                id_shift_data_main: this.cArgs.id_shift_data_main,
                                id_shift_ref_applicants: this.cArgs.id_shift_ref_applicants,
                                responsibility: this.cArgs.responsibility.name,
                                description: this.cArgs.description,
                                return_type: "shift_profile"
                            })
                        .then((res) => {
                            this.cReturn = res.data;
                            this.$emit("update:result", 1);
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            })
        },


        closeModal() {
            this.$emit("update:show", false);
        },




    },
};
</script>

<style scoped>

</style>
